<template>
  <div class="services pad-60" id="services">
    <!--Shape-->
    <div class="shape">
      <img src="@/assets/images/icons/triangle2.png" />
    </div>
    <div class="container">
      <div class="row align-items-center">
        <div class="col-md-5 offset-md-1">
          <!--Text-->
          <div class="text">
            <div class="main_title diff_style">
              <h3>{{ $t("services.title") }}</h3>
            </div>
            <p>
              منشور التجارب هي شركة سعودية تقنية تحرص على دعم احتياجات عملاءها و افكارهم بتقديمها افضل الخيارات التقنية و الخبرات العملية لتمكنهم  من الوصول الى التجربة الامثل و تحقيق رؤيتهم التقنية.
            </p>
            <!-- <router-link to="#" class="main_button serv"
              ><span>{{ $t("seeMore") }}</span></router-link
            > -->
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div
              class="col-md-6 serv_cont"
              v-for="ser in services"
              :key="ser.id"
            >
              <ServiceCard :item="ser"></ServiceCard>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ServiceCard from "../UI/ServiceCard.vue";
export default {
  components: {
    ServiceCard,
  },
  data() {
    return {
      services: [

        {
          id: Math.random()
            .toString(36)
            .replace(/[^a-z]+/g, "")
            .substr(0, 5),
          image: require("@/assets/images/icons/ser2.png"),
          title: this.$t("services.title2"),
          desc: this.$t("services.desc2"),
        },
        {
          id: Math.random()
            .toString(36)
            .replace(/[^a-z]+/g, "")
            .substr(0, 5),
          image: require("@/assets/images/icons/ser3.svg"),
          title: this.$t("services.title3"),
          desc: this.$t("services.desc3"),
        },
        {
          id: Math.random()
            .toString(36)
            .replace(/[^a-z]+/g, "")
            .substr(0, 5),
          image: require("@/assets/images/icons/ser1.png"),
          title: this.$t("services.title1"),
          desc: this.$t("services.desc1"),
        },
        {
          id: Math.random()
            .toString(36)
            .replace(/[^a-z]+/g, "")
            .substr(0, 5),
          image: require("@/assets/images/icons/ser4.png"),
          title: this.$t("services.title4"),
          desc: this.$t("services.desc4"),
        },
      ],
    };
  },
};
</script>

<style></style>
