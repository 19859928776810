<template>
  <div class="main_section">
    <!--Left Image-->
    <div class="left_image">
      <img src="@/assets/images/icons/image2.png" />
    </div>
    <div class="container">
      <div class="row align-items-center">
        <div class="col-md-5">
          <!--Details-->
          <div class="details">
            <!--Title-->
            <h4>{{ $t("mainSection.mainTitle") }}</h4>
            <!--Sub Title-->
            <span>{{ $t("mainSection.subTitle") }}</span>
            <!--Desc-->
            <p>{{ $t("mainSection.desc") }}</p>
            <!--Contact-->
            <router-link to="" class="main_button contact"
              ><span>{{ $t("navbar.contact") }}</span></router-link
            >
          </div>
        </div>
        <div class="col-md-7">
          <!--Image-->
          <div class="image">
            <img src="@/assets/images/main_section/image.png" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
