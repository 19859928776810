<template>
  <div class="technology_used pad-60">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <!--Main Title-->
          <div class="main_title center">
            <h2>{{ $t("technology.title") }}</h2>
          </div>
        </div>
        <div class="col-md-2 tech" v-for="tech in technologies" :key="tech.id">
          <!--Tech Card-->
          <div class="tech_content">
            <img :src="tech.image" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      technologies: [
        {
          id: Math.random()
            .toString(36)
            .replace(/[^a-z]+/g, "")
            .substr(0, 5),
          image: require("@/assets/images/technology/img1.png"),
        },
        {
          id: Math.random()
            .toString(36)
            .replace(/[^a-z]+/g, "")
            .substr(0, 5),
          image: require("@/assets/images/technology/img2.png"),
        },
        {
          id: Math.random()
            .toString(36)
            .replace(/[^a-z]+/g, "")
            .substr(0, 5),
          image: require("@/assets/images/technology/img3.png"),
        },
        {
          id: Math.random()
            .toString(36)
            .replace(/[^a-z]+/g, "")
            .substr(0, 5),
          image: require("@/assets/images/technology/img4.png"),
        },
        {
          id: Math.random()
            .toString(36)
            .replace(/[^a-z]+/g, "")
            .substr(0, 5),
          image: require("@/assets/images/technology/img5.png"),
        },
        {
          id: Math.random()
            .toString(36)
            .replace(/[^a-z]+/g, "")
            .substr(0, 5),
          image: require("@/assets/images/technology/img15.png"),
        },
        {
          id: Math.random()
            .toString(36)
            .replace(/[^a-z]+/g, "")
            .substr(0, 5),
          image: require("@/assets/images/technology/img7.png"),
        },
        {
          id: Math.random()
            .toString(36)
            .replace(/[^a-z]+/g, "")
            .substr(0, 5),
          image: require("@/assets/images/technology/img8.png"),
        },
        {
          id: Math.random()
            .toString(36)
            .replace(/[^a-z]+/g, "")
            .substr(0, 5),
          image: require("@/assets/images/technology/img10.png"),
        },
        {
          id: Math.random()
            .toString(36)
            .replace(/[^a-z]+/g, "")
            .substr(0, 5),
          image: require("@/assets/images/technology/img11.png"),
        },
        {
          id: Math.random()
            .toString(36)
            .replace(/[^a-z]+/g, "")
            .substr(0, 5),
          image: require("@/assets/images/technology/img24.png"),
        },
        {
          id: Math.random()
            .toString(36)
            .replace(/[^a-z]+/g, "")
            .substr(0, 5),
          image: require("@/assets/images/technology/img20.png"),
        },
      ],
    };
  },
};
</script>

<style></style>
