<template>
  <header class="header" :class="{ is_sticky: scrollPosition > 50 }">
    <!--Navbar-->
    <nav class="navbar navbar-expand-lg">
      <div class="container">
        <!--Logo-->
        <router-link class="navbar-brand" to="/"
          ><img src="@/assets/images/icons/icon.png" class="main-logo"
        /></router-link>
        <!--Logo-->
        <!--Button Collapse Sho In Mobile-->
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          @click="showNavbar"
        >
          <span class="navbar-toggler-icon"><i class="fas fa-bars"></i></span>
        </button>
        <!--Button Collapse Sho In Mobile-->
        <!--Navbar Container-->
        <div class="navbar-collapse" :class="{ show: showNav }">
          <!--Navbar Menu-->
          <ul class="navbar-nav">
            <li class="nav-item">
              <router-link class="nav-link" to="/" exact="">{{
                $t("navbar.home")
              }}</router-link>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#about">{{ $t("navbar.about") }}</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#services">{{
                $t("navbar.services")
              }}</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#works">{{ $t("navbar.works") }}</a>
            </li>
            <!-- <li class="nav-item">
              <a class="nav-link" href="#blog">{{ $t("navbar.blog") }}</a>
            </li> -->
            <!-- <li class="nav-item">
              <a class="nav-link" href="#client">{{ $t("navbar.clients") }}</a>
            </li> -->
            <li class="nav-item">
              <a class="nav-link" href="#contact">{{ $t("navbar.contact") }}</a>
            </li>
          </ul>
          <!--Navbar Menu-->
          <ul class="mr-auto icons">
            <li class="theme">
              <p style="color:white">COMPANY MANSHOUR AL-TEJARAB FOR INFORMATION TECHNOLOGY</p>
            </li>
            <!-- <li class="lang">
              <button @click="changeLang"><i class="fal fa-globe"></i></button>
            </li> -->
          </ul> 
          <!--Hide Navbar-->
          <button class="close_menu" @click="HideNavbar">
            <i class="fas fa-times"></i>
          </button>
          <!--Hide Navbar-->
        </div>
        <!--Navbar Container-->
      </div>
    </nav>
  </header>
</template>

<script>
export default {
  data() {
    return {
      //Sticky
      scrollPosition: null,

      //Show Menu
      show: false,

      //Show Navbar
      showNav: false,
    };
  },
  methods: {
    //Update Scroll
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
    //Show Menu
    showMenu() {
      this.show = true;
    },
    //Show Menu
    //Close Menu When Body Click
    close(e) {
      if (!this.$el.contains(e.target)) {
        this.show = false;
      }
    },
    //Show Navbar
    showNavbar() {
      this.showNav = true;
    },
    //Hide Navbar
    HideNavbar() {
      this.showNav = false;
    },
    //Hide Navbar
    //Show Navbar
    changeTheme() {
      this.$store.dispatch("theme_module/changeTheme");
      if (this.$store.getters["theme_module/current_theme"] == "light") {
        this.$vuetify.theme.dark = false;
      } else {
        this.$vuetify.theme.dark = true;
      }
    },

    changeLang() {
      this.$store.dispatch("lang_module/switchLang");
    },
  },
  mounted() {
    window.addEventListener("scroll", this.updateScroll);
  },
};
</script>

<style lang="scss" scoped></style>
