<template>
  <footer class="footer">
    <div class="container">
      <div class="row">
        <!--Foot About-->
        <div class="col-md-3">
          <div class="foot_about">
            <img src="@/assets/images/icons/logo2.png" />
            <span>{{ $t("footer.subTitle") }}</span>
            <p>{{ $t("footer.desc") }}</p>
            <p>{{ $t("footer.desc2") }}</p>
          </div>
        </div>
        <!--Links-->
        <div class="col-md-1">
          <!-- <div class="foot">
            <h4>{{ $t("footer.importantLinks") }}</h4>
            <ul class="links">
              <li>
                <router-link to="">{{ $t("footer.aboutUs") }}</router-link>
              </li>
              <li>
                <router-link to="">{{ $t("footer.team") }}</router-link>
              </li>
              <li>
                <router-link to="">{{ $t("footer.works") }}</router-link>
              </li>
              <li>
                <router-link to="">{{ $t("footer.blog") }}</router-link>
              </li>
              <li>
                <router-link to="">{{ $t("footer.map") }}</router-link>
              </li>
              <li>
                <router-link to="">{{ $t("footer.faqs") }}</router-link>
              </li>
              <li>
                <router-link to="">{{ $t("footer.terms") }}</router-link>
              </li>
            </ul>
          </div> -->
        </div>
        <!--Services-->
        <div class="col-md-3">
          <div class="foot">
            <h4>{{ $t("footer.services") }}</h4>
            <ul class="links">
              <li>
                <router-link to="">{{ $t("footer.website") }}</router-link>
              </li>
              <li>
                <router-link to="">{{ $t("footer.apps") }}</router-link>
              </li>
              <!-- <li>
                <router-link to="">{{ $t("footer.matgar") }}</router-link>
              </li> -->
              <li>
                <router-link to="">{{ $t("footer.systems") }}</router-link>
              </li>
              <li>
                <router-link to="">{{ $t("footer.develop") }}</router-link>
              </li>
              <li>
                <router-link to="">{{ $t("footer.hosting") }} | {{ $t("footer.support") }}</router-link>
              </li>
            </ul>
          </div>
        </div>
        <!--Contact-->
        <div class="col-md-3">
          <div class="foot">
            <h4>{{ $t("footer.contactUs") }}</h4>
            <ul class="links">
              <li>
                <span><i class="fal fa-phone"></i></span>
                <a href="https://wa.me/+9660500711915"
                  >0500711915</a
                >
              </li>
              <li>
                <span><i class="fal fa-map-marker-alt"></i></span>
                <a> الرياض</a>
              </li>
              <!-- <li>
                <span><i class="fal fa-whatsapp"></i></span>
                <a href="https://wa.me/+966500711915"
                  >0500711915</a
                >
              </li> -->
              <li>
                <span><i class="fal fa-envelope"></i></span>
                <a href="mailto:info@byeptec.com"
                  >info@byeptec.com</a
                >
              </li>
            </ul>
          </div>
        </div>
        <!--CopyRight-->
        <div class="col-md-12">
          <div class="copyright">
            <p> COMPANY MANSHOUR AL

-TEJARAB FOR INFORMATION

TECHNOLOGY &copy; 2025</p>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
